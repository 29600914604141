import Vue from 'vue'
import Router from 'vue-router'
import IndexPage from '@/pages/Index.vue'
import PowerCenterPage from '@/pages/PowerCenter.vue'
import AssetsPage from "@/pages/Assets.vue";
// import AppPage from '@/pages/App.vue'
Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            component: IndexPage
        },
        {
            path: '/powercenter',
            name: 'powercenter',
            component: PowerCenterPage
        },
        {
            path: '/assets',
            name: 'assets',
            component: AssetsPage
        }
    ]
})
