<template>
  <div class="head">
    <div class="left">
      <!--    <a href="./index.html" class="current">首页</a>-->
      <router-link to="/" :class="{ current: $route.path === '/' }">首页</router-link>
      <router-link to="/powercenter" :class="{ current: $route.path === '/powercenter' }">算力中心</router-link>
      <router-link to="/assets" :class="{ current: $route.path === '/assets' }">我的资产</router-link>
    </div>
    <div class="right">
      <div class="search">
        <input type="text" placeholder="智能边缘云" class="words">
        <input type="submit" value="" class="send">
      </div>
      <div class="lan">
        <h2 class="h2tit">Zh</h2>
        <div class="slide">
          <a href="#">En</a>
        </div>
      </div>
      <a href="javascript:;" class="connect" @click="connect_wallet">{{ showName() }}</a>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "HeaderComponent",
  computed: {
    ...mapState(['wallet_address']),
  },
  methods: {
    ...mapActions(['connect_wallet']),
    showName() {
      if (this.wallet_address != null) {
        return this.wallet_address.substring(0, 6) + '...' + this.wallet_address.substring(this.wallet_address.length - 4, this.wallet_address.length)
      }
      return "链接钱包"
    },
  }
}
</script>

<style scoped>

</style>