<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer.vue";
import {mapState} from "vuex";
import axios from "axios";

export default {
  name: "AssetsPage",
  components: {Header, Footer},
  computed: {
    ...mapState(['wallet_address'])
  },
  mounted() {
    this.load();
    setTimeout(() => {
      this.load();
    }, 5000);
  },
  data() {
    return {
      option1: {
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 12,
            color: '#6F767E'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#EFEFEF'
            }
          }
        },
        legend: {
          right: '30px',
          itemWidth: 12,
          itemHeight: 12,
          borderRadius: 4
        },
        grid: {
          left: '0%',
          right: '3%',
          top: '0%',
          bottom: '2%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          show: false
        },
        tooltip: {
          trigger: 'axis', // Show tooltip when hovering over axis
          axisPointer: {
            type: 'shadow' // Show shadow pointer
          }
        },
        series: [
          {
            name: '我的总算力',
            data: [],
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              color: '#81DACF'
            }
          },
          {
            name: '单日算力产出',
            data: [],
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              color: '#B981DA'
            }
          },
          {
            name: '总产出',
            data: [],
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              color: '#0C68E9'
            }
          }
        ]
      },
      myTotalProduced: 0,
      canWithdrawAmount: 0,
      logs: [],
      rtx: 0,
    }
  },
  watch: {
    wallet_address: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        if (newValue) {
          this.load();
        } else {
          this.myTotalProduced = 0;
          this.canWithdrawAmount = 0;
          this.logs = [];
          this.rtx = 0;
        }
      },
      immediate: true
    }
  },
  methods: {
    async load() {
      if (this.wallet_address) {
        let a = await axios.get('/myAssets/' + this.wallet_address);
        console.log(a.data);
        this.myTotalProduced = a.data.myTotalProduced;
        this.canWithdrawAmount = a.data.canWithdrawAmount;
        this.logs = a.data.claimLogs;
        this.rtx = a.data.amount;

        const daily = a.data.daily;

        this.option1.xAxis.data = [];
        this.option1.series[0].data = [];
        this.option1.series[1].data = [];
        this.option1.series[2].data = [];
        for (let i = 0; i < daily.length; i++) {
          this.option1.xAxis.data.push(daily[i].createDate);
          this.option1.series[0].data.push(daily[i].totalPower);
          this.option1.series[1].data.push(daily[i].produced);
          this.option1.series[2].data.push(daily[i].totalProduced);
        }

      }
    }
  }
}
</script>

<template>
  <div>
    <Header/>

    <div class="index">
      <div class="section5">
        <div class="content1">
          <div class="left">
            <div class="val1">
              <div class="tit">{{ rtx }} <b>RTX4090</b></div>
              <div class="tis">
                持有算力
              </div>
            </div>
            <select name="" id="" class="sel">
              <option value="">每日</option>
            </select>
            <div class="clear"></div>
            <div class="charts">
              <div id="main1">
                <v-chart class="chart" :option="option1" autoresize/>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title">提取功能</div>
            <!--            <div class="val">$3,326.<b>18</b></div>-->
            <div class="val">{{ canWithdrawAmount }} Aleo</div>

            <div class="list">
              <p>
                <span class="icon"><img src="images/icon2.png" alt=""></span>
                <span class="ris">
                  <span class="val1">{{ myTotalProduced }} Aleo</span>
                  <span class="tit">总产量</span>
                </span>
              </p>
              <p>
                <span class="icon"><img src="images/icon3.png" alt=""></span>
                <span class="ris">
                  <span class="val1">{{ canWithdrawAmount }} Aleo</span>
                  <span class="tit">余额</span>
                </span>
              </p>
            </div>
            <a href="#" class="more">提取收益</a>
          </div>
        </div>
        <div class="content2">
          <div class="title">提取订单记录</div>
          <select name="" id="" class="sel">
            <option value="全部">全部</option>
          </select>
          <div class="clear"></div>
          <div class="list">
            <div v-for="log in logs" :key="log.txHash">
              <div class="item">
                <div class="let">
                  <div class="icon"><img src="images/icon1.png" alt=""></div>
                  <div class="val1">
                    <div class="s1">15428456.00U</div>
                    <div class="s2">提取</div>
                  </div>
                </div>
                <div class="ris">
                  2024/06/24 07:45:00 PM
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<style scoped>

</style>