<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer.vue";
import {mapActions, mapState} from "vuex";
import axios from "axios";

export default {
  name: "PowerCenter",
  components: {Header, Footer},
  computed: {
    ...mapState(['wallet_address'])
  },
  mounted() {
    this.load();
    setTimeout(() => {
      this.load();
    }, 3000);
  },
  data() {
    return {
      logs: [],
      rtx: 0
    }
  },
  watch: {
    wallet_address: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        if (newValue) {
          this.load();
        } else {
          this.logs = [];
          this.rtx=0;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['buyNode',]),
    async onClickBuyNode(type) {
      try {
        await this.buyNode(type)
        this.$message.success("购买成功");
      } catch (e) {
        this.$message.error(e.toString());
      }
    },
    async load() {
      if (this.wallet_address) {
        let a = await axios.get('/buyNodeTx/' + this.wallet_address);
        console.log(a.data);
        this.logs = a.data.tx;
        this.rtx = a.data.amount;
      }
    },
    clickCopy() {
      try {
        navigator.clipboard.writeText("https://aleocloud.org/?invator=" + this.wallet_address).then(
            () => {
              this.$message.success("Copy Success");
            },
            (err) => {
              console.error("Failed to copy text: ", err);
            }
        );
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
  }
}
</script>

<template>
  <div>
    <Header/>

    <div class="index type2">


      <div class="section2">
        <div class="wrap">
          <div class="list">
            <div class="item">
              <div class="title">小节点4090</div>
              <div class="dec">通过智能合约管理所有的计算资源，确保每一份算力的分配和收益都公开透明。</div>
              <div class="list">
                <p>
                  <span class="s1">规格</span>
                  <span class="s2">32核64线程</span>
                </p>
                <p>
                  <span class="s1">系统盘大小（GB）</span>
                  <span class="s2">40</span>
                </p>
                <p>
                  <span class="s1">带宽（M）</span>
                  <span class="s2">10</span>
                </p>
                <p>
                  <span class="s1">时长</span>
                  <span class="s2">90天</span>
                </p>
              </div>
              <div class="tits">
                <div class="s1">新用户专享</div>
                <div class="s2">剩余1000台</div>
              </div>
              <div class="val">800U</div>
              <div class="dec2">立省2,677.20U</div>
              <a href="javascript:;" class="more" @click="onClickBuyNode(1)">立即订购</a>
            </div>
            <div class="item">
              <div class="last">限时95折</div>
              <div class="title">中节点5台4090</div>
              <div class="dec">通过智能合约管理所有的计算资源，确保每一份算力的分配和收益都公开透明。</div>
              <div class="list">
                <p>
                  <span class="s1">规格</span>
                  <span class="s2">32核64线程</span>
                </p>
                <p>
                  <span class="s1">系统盘大小（GB）</span>
                  <span class="s2">40</span>
                </p>
                <p>
                  <span class="s1">带宽（M）</span>
                  <span class="s2">10</span>
                </p>
                <p>
                  <span class="s1">时长</span>
                  <span class="s2">90天</span>
                </p>
              </div>
              <div class="tits">
                <div class="s1">新用户专享</div>
                <div class="s2">剩余1000台</div>
              </div>
              <div class="val">3800U</div>
              <div class="dec2">立省2,677.20U</div>
              <a href="javascript:;" class="more" @click="onClickBuyNode(2)">立即订购</a>
            </div>
            <div class="item">
              <div class="last">限时95折</div>
              <div class="title">大节点10台4090</div>
              <div class="dec">通过智能合约管理所有的计算资源，确保每一份算力的分配和收益都公开透明。</div>
              <div class="list">
                <p>
                  <span class="s1">规格</span>
                  <span class="s2">32核64线程</span>
                </p>
                <p>
                  <span class="s1">系统盘大小（GB）</span>
                  <span class="s2">40</span>
                </p>
                <p>
                  <span class="s1">带宽（M）</span>
                  <span class="s2">10</span>
                </p>
                <p>
                  <span class="s1">时长</span>
                  <span class="s2">90天</span>
                </p>
              </div>
              <div class="tits">
                <div class="s1">新用户专享</div>
                <div class="s2">剩余1000台</div>
              </div>
              <div class="val">7200U</div>
              <div class="dec2">立省2,677.20U</div>
              <a href="javascript:;" class="more" @click="onClickBuyNode(3)">立即订购</a>
            </div>
          </div>
          <div class="right">
            <img src="images/ewm.png" alt="">
            <div class="dec" v-if="wallet_address">
              持有算力 : {{ rtx }} * RTX4090
              <br/>
              邀请好友购买算力可获得10%的USDT奖励直接发放到账

            </div>
<!--            <div class="dec">-->
<!--            </div>-->

            <button @click="clickCopy" class="copy" type="button" v-if="wallet_address">复制链接</button>
          </div>
        </div>
      </div>


      <div class="section5 type2">
        <div class="content2">
          <div class="title">购买订单记录</div>
          <select name="" id="" class="sel">
            <option value="全部">全部</option>
          </select>
          <div class="clear"></div>
          <div class="list">
            <div v-for="log in logs" :key="log.txHash">
              <a :href="'https://bscscan.com/tx/' + log.txHash" target="_blank">
                <div class="item">
                  <div class="let">
                    <div class="icon"><img src="images/arrow-narrow-up-right.png" alt=""></div>
                    <div class="val1">
                      <div class="s1">
                        <span v-if="log.nodeType === 1">800U</span>
                        <span v-else-if="log.nodeType === 2">3800U</span>
                        <span v-else-if="log.nodeType === 3">7200U</span>
                        <span v-else>未知类型</span>
                      </div>
                      <div class="s2">购买</div>
                    </div>
                  </div>
                  <div class="ris">
                    {{ new Date(log.blockTime * 1000).toLocaleString() }}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>

    <Footer/>
  </div>
</template>

<style scoped>

</style>