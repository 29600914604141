<template>
  <div>
    <Header/>
    <div class="banner">
      <img src="images/cbanner.jpg" alt="">
      <div class="content">
        <div class="title">基于Aleo网络的一站式<b>云算力平台</b>
        </div>
        <div class="dec">
          Aleo云算力平台是一个去中心化的云计算资源分配平台，利用Aleo区块链的隐私保护和高效计算能力，提供给用户一个稳定、透明的算力获取渠道。平台通过智能合约管理所有的计算资源，确保每一份算力的分配和收益都公开透明。
        </div>
      </div>
    </div>
    <div class="index">
      <div class="section1">
        <div class="wrap">
          <div class="list">
            <div class="item">
              <div class="title">优势1 <b>平台性能</b>
              </div>
              <div class="dec">顶级配置 算力满跑</div>
            </div>
            <div class="item">
              <div class="title">优势2 <b>极速交付</b>
              </div>
              <div class="dec">主网上线 T+0交付</div>
            </div>
            <div class="item">
              <div class="title">优势3 <b>最高性价比</b>
              </div>
              <div class="dec">0押金 低单价 即租即挖</div>
            </div>
            <div class="item">
              <div class="title">优势4 <b>一站式服务</b>
              </div>
              <div class="dec">平台运维 实时匹配最优挖矿方案</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section2">
        <div class="wrap">
          <div class="list">
            <div class="item">
              <div class="title">小节点4090</div>
              <div class="dec">通过智能合约管理所有的计算资源，确保每一份算力的分配和收益都公开透明。</div>
              <div class="list">
                <p>
                  <span class="s1">规格</span>
                  <span class="s2">32核64线程</span>
                </p>
                <p>
                  <span class="s1">系统盘大小（GB）</span>
                  <span class="s2">480G</span>
                </p>
                <p>
                  <span class="s1">带宽（M）</span>
                  <span class="s2">10</span>
                </p>
                <p>
                  <span class="s1">时长</span>
                  <span class="s2">90天</span>
                </p>
                <p>
                  <span class="s1">内存</span>
                  <span class="s2">64G * 16条</span>
                </p>
                <p>
                  <span class="s1">数据盘</span>
                  <span class="s2">3.84T SSD * 2</span>
                </p>
                <p>
                  <span class="s1">GPU</span>
                  <span class="s2">4090 24G * 8</span>
                </p>
                <p>
                  <span class="s1">网卡</span>
                  <span class="s2">25G</span>
                </p>
              </div>
              <div class="tits">
                <div class="s1">新用户专享</div>
                <div class="s2">剩余1000台</div>
              </div>
              <div class="val">800U</div>
              <div class="dec2">立省2,677.20U</div>
              <a href="javascript:;" class="more" @click="onClickBuyNode(1)">立即订购</a>
            </div>
            <div class="item">
              <div class="last">限时95折</div>
              <div class="title">中节点5台4090</div>
              <div class="dec">通过智能合约管理所有的计算资源，确保每一份算力的分配和收益都公开透明。</div>
              <div class="list">
                <p>
                  <span class="s1">规格</span>
                  <span class="s2">32核64线程</span>
                </p>
                <p>
                  <span class="s1">系统盘大小（GB）</span>
                  <span class="s2">40</span>
                </p>
                <p>
                  <span class="s1">带宽（M）</span>
                  <span class="s2">10</span>
                </p>
                <p>
                  <span class="s1">时长</span>
                  <span class="s2">90天</span>
                </p>
                <p>
                  <span class="s1">内存</span>
                  <span class="s2">64G * 16条</span>
                </p>
                <p>
                  <span class="s1">数据盘</span>
                  <span class="s2">3.84T SSD * 2</span>
                </p>
                <p>
                  <span class="s1">GPU</span>
                  <span class="s2">4090 24G * 8</span>
                </p>
                <p>
                  <span class="s1">网卡</span>
                  <span class="s2">25G</span>
                </p>
              </div>
              <div class="tits">
                <div class="s1">新用户专享</div>
                <div class="s2">剩余1000台</div>
              </div>
              <div class="val">3800U</div>
              <div class="dec2">立省2,677.20U</div>
              <a href="javascript:;" class="more" @click="onClickBuyNode(2)">立即订购</a>
            </div>
            <div class="item">
              <div class="last">限时95折</div>
              <div class="title">大节点10台4090</div>
              <div class="dec">通过智能合约管理所有的计算资源，确保每一份算力的分配和收益都公开透明。</div>
              <div class="list">
                <p>
                  <span class="s1">规格</span>
                  <span class="s2">32核64线程</span>
                </p>
                <p>
                  <span class="s1">系统盘大小（GB）</span>
                  <span class="s2">40</span>
                </p>
                <p>
                  <span class="s1">带宽（M）</span>
                  <span class="s2">10</span>
                </p>
                <p>
                  <span class="s1">时长</span>
                  <span class="s2">90天</span>
                </p>
                <p>
                  <span class="s1">内存</span>
                  <span class="s2">64G * 16条</span>
                </p>
                <p>
                  <span class="s1">数据盘</span>
                  <span class="s2">3.84T SSD * 2</span>
                </p>
                <p>
                  <span class="s1">GPU</span>
                  <span class="s2">4090 24G * 8</span>
                </p>
                <p>
                  <span class="s1">网卡</span>
                  <span class="s2">25G</span>
                </p>
              </div>
              <div class="tits">
                <div class="s1">新用户专享</div>
                <div class="s2">剩余1000台</div>
              </div>
              <div class="val">7200U</div>
              <div class="dec2">立省2,677.20U</div>
              <a href="javascript:;" class="more" @click="onClickBuyNode(3)">立即订购</a>
            </div>
          </div>
        </div>
      </div>
      <div class="section3">
        <div class="wrap">
          <div class="title">统计和指标</div>
          <div class="dec">实时更新显示网络每日总计算能力和平台每日计算能力</div>
          <div class="content">
            <div class="val1">
              <div class="tit">{{ networkTotalPower }}<b> C/S</b></div>
<!--              <div class="per up">-->
<!--                85,66%-->
<!--              </div>-->
<!--              <div class="per down">-->
<!--                85,66%-->
<!--              </div>-->
            </div>
            <select name="" id="" class="sel">
              <option value="">每日</option>
            </select>
            <div class="clear"></div>
            <div class="charts">
              <div id="main1">
                <v-chart class="chart" :option="option" autoresize/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="wrap">
          <div class="title">平台概述</div>
          <div class="dec">应需而为，推动创新技术与场景应用深度融合</div>
          <div class="content">
            <div class="left">
              <div class="title1">计算能力平台</div>
              <div class="dec">
                Aleo云算力平台致力于为用户提供一个高效、安全、便捷的计算力获取渠道，助力用户参与Aleo区块链的计算任务，实现资源的最佳利用和收益最大化。
              </div>
              <div class="dec2">
                <p>
                  <b>绿色高效</b> 能耗低自建云计算中心，有效节约电力和硬件成本。
                </p>
                <p>
                  <b>经济可控</b> 符合云计算最佳实践配置，减少设备成本投入。
                </p>
                <p>
                  <b>运营优化</b> 稳态业务和敏态业务有效分离，资源利用率和生命周期运营可控。
                </p>
                <p>
                  <b>降本增效</b> 结合私有云资源超高复用比运营配置，硬件支出有效降低约 50%。
                </p>
                <p>
                  <b>降本增效</b> 结合私有云资源超高复用比运营配置，硬件支出有效降低约 50%。
                </p>
                <p>
                  <b>降本增效</b> 结合私有云资源超高复用比运营配置，硬件支出有效降低约 50%。
                </p>
                <p>
                  <b>降本增效</b> 结合私有云资源超高复用比运营配置，硬件支出有效降低约 50%。
                </p>
                <p>
                  <b>降本增效</b> 结合私有云资源超高复用比运营配置，硬件支出有效降低约 50%。
                </p>
              </div>
            </div>
            <div class="right">
              <div class="item">
                <a href="#">
                  <span class="title1">特性</span>
                  <span class="dec">低门槛构建虚拟化基础底座，到起步轻量简单的超…</span>
                  <img src="images/bg1.png" alt="">
                </a>
              </div>
              <div class="item">
                <a href="#">
                  <span class="title1">工作原理</span>
                  <span class="dec">通过容器和虚拟机的混合部署引入丰富的云原生技术…</span>
                  <img src="images/bg2.png" alt="">
                </a>
              </div>
            </div>
            <a href="#" class="more">查<br>看<br>更<br>多</a>

          </div>
        </div>
      </div>
    </div>


    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer.vue";
// import {CanvasRenderer} from 'echarts/renderers';
// import VChart from 'vue-echarts'
// import $ from 'jquery'
import {mapActions, mapState} from "vuex";
import axios from "axios";

// import Swiper from 'swiper';

export default {
  name: "IndexPage",
  components: {Footer, Header},
  computed: {
    ...mapState(['wallet_address', 'aleo_contract'])
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '0%',
          right: '3%',
          top: '0%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['06/23', '06/24', '06/25', '06/26', '06/27', '06/28'],
          axisLabel: {
            fontSize: 12,
            color: '#6F767E'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#EFEFEF'
            }
          }
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [
          {
            name: '网络总计算',
            data: [820, 932, 901, 934, 1290, 1330],
            type: 'line',
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: '#FBA94B'},
                  {offset: 1, color: 'rgba(251, 169, 75, 0)'}
                ]
              }
            },
            lineStyle: {
              color: '#FBA94B',
              width: 1
            },
            itemStyle: {
              color: '#FBA94B',
              borderColor: '#FBA94B',
              borderWidth: 1
            }
          },
          {
            name: '平台计算',
            data: [720, 832, 801, 834, 1190, 1230],
            type: 'line',
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: '#9CC5FF'},
                  {offset: 1, color: 'rgba(55, 63, 239, 0)'}
                ]
              }
            },
            lineStyle: {
              color: '#0c68e9',
              width: 1
            },
            itemStyle: {
              color: '#0c68e9',
              borderColor: '#0c68e9',
              borderWidth: 1
            }
          }
        ]
      },
      networkTotalPower: 0,
    }
  },
  methods: {
    ...mapActions(['buyNode']),
    async onClickBuyNode(type) {
      try {
        await this.buyNode(type)
        this.$message.success("购买成功");
      } catch (e) {
        this.$message.error(e.toString());
      }
    },
    async load() {
      let a = await axios.get('/getStatistics');
      this.option.xAxis.data = [];
      this.option.series[0].data = [];
      this.option.series[1].data = [];
      for (let i = 0; i < a.data.length; i++) {
        this.option.xAxis.data.push(a.data[i].createDate);
        this.option.series[0].data.push(a.data[i].networkTotalPower);
        this.option.series[1].data.push(a.data[i].platformTotalPower);
      }
      this.networkTotalPower = a.data[a.data.length - 1].networkTotalPower;
      console.log(a.data)
      // this.option.xAxis.data = createDate;
      // this.option.series = [networkTotalPower, platformTotalPower];
    }

  }
}
</script>

<style scoped>

</style>