<script>
export default {
  name: "FooterComponent"
}
</script>

<template>
  <div class="footer">
    <div class="title1">售前咨询热线：<b>400-810-9889转1</b></div>
    <div class="link">
      <a href="https://x.com/Aleo_cloud" target="_blank"><img src="images/li1.png" alt=""></a>
      <a href="#"><img src="images/li2.png" alt=""></a>
      <a href="#"><img src="images/li3.png" alt=""></a>
    </div>
  </div>
</template>

<style scoped>

</style>