import Vue from 'vue'
import Vuex from 'vuex'
import {ethers} from 'ethers';
import {usdt_abi} from "@/store/usdt_abi";
import {aleo_abi} from "@/store/aleo_abi";
import {formatEther} from "ethers/lib/utils";

Vue.use(Vuex)

const usdt_contract_address = '0x55d398326f99059fF775485246999027B3197955';
const aleo_contract_address = '0xe80aBfA81108f443CD75BD89d795c67817aC4bf9';

export default new Vuex.Store({
    state: {
        wallet_address: null,
        provider: null,
        signer: null,
        usdt_contract: null,
        aleo_contract: null,
        aleo_contract_address: aleo_contract_address,
        usdt_contract_address: usdt_contract_address
    },
    getters: {},
    mutations: {},
    actions: {
        async connect_wallet({dispatch}) {
            if (this.state.wallet_address) {
                await dispatch('disconnect_wallet')
                return;
            }

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const accounts = await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner(accounts[0]);


            let chain = await provider.getNetwork();
            let code = chain.chainId;
            if (code !== 56) {
                // await this.switch_custom_network();
                await dispatch('switch_custom_network')

            }

            this.state.wallet_address = accounts[0];
            this.state.provider = provider;
            this.state.signer = signer;
            this.state.usdt_contract = new ethers.Contract(usdt_contract_address, usdt_abi, signer);
            this.state.aleo_contract = new ethers.Contract(aleo_contract_address, aleo_abi, signer);

        },
        async disconnect_wallet() {
            this.state.wallet_address = null;
            this.state.provider = null;
            this.state.signer = null;
        },
        async switch_custom_network() {
            const name = 'Binance Smart Chain Mainnet';
            const chainID = 56;
            const rpc = 'https://bsc-pokt.nodies.app';
            const symbol = 'BNB';
            const explorer = 'https://bscscan.com';
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: `0x${chainID.toString(16)}`,
                    chainName: name,
                    rpcUrls: [rpc],
                    nativeCurrency: {
                        name: symbol,
                        symbol: symbol,
                        decimals: 18
                    },
                    blockExplorerUrls: [explorer]
                }]
            });

            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: `0x${chainID.toString(16)}`}] // 16 进制表示的链 ID
            });

        },
        async get_usdt_allowance({state}, spender) {
            let allowance = await state.usdt_contract.allowance(state.wallet_address, spender);
            return allowance;
        },
        async set_usdt_approve({state}, spender, amount) {
            let tx = await state.usdt_contract.approve(spender, amount);
            return tx;
        },
        async get_usdt_balance({state}, owner) {
            let balance = await state.usdt_contract.balanceOf(owner);
            return balance;
        },
        async buyNode({state, dispatch}, type) {
            let price = 999999;
            if (type === 1) {
                price = 800;
            }
            if (type === 2) {
                price = 3800;
            }
            if (type === 7) {
                price = 3800;
            }


            if (!state.wallet_address) {
                await dispatch('connect_wallet');
            }

            console.log('type', type);
            let balance = await state.usdt_contract.balanceOf(state.wallet_address);
            balance = Number(formatEther(balance));
            console.log('balance', balance);
            if (balance < price) {
                throw new Error('USDT不足');
            }

            let allowance = await state.usdt_contract.allowance(state.wallet_address, state.aleo_contract_address);
            allowance = (Number)(formatEther(allowance));
            console.log('allowance', allowance)

            if (allowance < price) {
                let tx = await state.usdt_contract.approve(
                    state.aleo_contract_address,
                    ethers.utils.parseEther(price.toString())
                );
                console.log("allowance tx",tx);
                let receipt = await tx.wait();
                if (receipt.status === 0) {
                    throw new Error('授权失败');
                }else{
                    console.log('授权成功')
                }
            }

            let tx = await state.aleo_contract.buyNode(type);
            console.log("buyNode tx",tx);
            let receipt = await tx.wait();
            if (receipt.status === 0) {
                throw new Error('购买失败');
            }else{
                console.log('购买成功')
            }


        }
    },
    modules: {}
})
